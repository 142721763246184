.akr_is-a_small_box {
  height: 100px;
  width: 100px;

  background: teal;
}

.akr_is {
  //   background: royalblue;

  height: 100vh;
  min-height: 100vh;

  width: 100vw;
  min-width: 100vw;

  position: relative;

  .akr_is-container {
    height: 100%;
    width: 100%;

    display: flex;

    &.centered {
      align-items: center;
      justify-content: center;
    }

    .akr_is-page-content {
      //
    }

    .akr_is-topbar {
      //
    }

    .akr_is-bottombar {
      //
    }

    .akr_is-leftbar {
      //
    }

    .akr_is-rightbar {
      //
    }
  }
}
