@keyframes cursorBlink {
  0% {
    opacity: 0;
  }
}

body {
  &.chakra-ui-light {
    .akr_is-logo {
      color: #1a202c;
    }
  }
  &.chakra-ui-dark {
    .akr_is-logo {
      color: #ced7e8;
    }
  }
}

.akr_is-logo {
  user-select: none;
  font-weight: bold;
  &.standalone  {
    //
  }

  .akr_is-logo_cursorBlock {
    &.animated-cursor {
      animation: cursorBlink 1.5s steps(2) infinite;
    }
  }

  &.small {
    font-size: 1em;
  }
  &.medium {
    font-size: 2em;
  }
  &.large {
    font-size: 3em;
  }
  &.xlarge {
    font-size: 4em;
  }
}
